import React from "react"
import { Link } from "gatsby"

const PostCard = (props) => {
  const myStyle = {};
  if(props.node.frontmatter.thumbnail) {
    myStyle.backgroundImage= `url(${
      props.node.frontmatter.thumbnail.childImageSharp.fluid.src
    })`;
  }

  if(props.isLast && props.count % 2 === 0){
    myStyle.height = '120vw';
  }

  return (
    <article
      className={`post-card ${props.count === 3 && `post-card-large`} ${
        props.postClass
      } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
      style={myStyle}
    >
      <Link to={props.node.fields.slug} className="post-card-link">
        <div className="post-card-content">
          <h2 className="post-card-title">
            {props.node.frontmatter.title || props.node.fields.slug}
          </h2>
        </div>
      </Link>
    </article>
  );
}

export default PostCard;
