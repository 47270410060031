import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} location={location}>
      <SEO title="ABout" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>
            My name is Abigail Grewal, I am a 25 year old print and womenswear designer. I have been in the fashion industry for over 6 years.
          </p>
          <p>
            I have just completed two degrees in fashion: the first in textiles and print from the London College of Fashion (LCF), and the second in womenswear design from Istituto Marangoni. Through my first degree, I discovered a love for prints and print techniques as I pushed myself to experiment with different colours and materials. My second degree culminated in the creation of my own capsule collection. Here, I was able to showcase the skills I had honed in design, pattern cutting, sewing, illustration, as well my digital skills. Out of 60 students in my final year, I was selected to be part of a group of 21 students who were invited to create a womenswear collection of six individual looks. Through these experiences I have gained knowledge of fabrics, including embroidery. I have also grown exponentially in understanding the backdrop against which the industry showcases its work.
          </p>
          <p>
            Prior to undertaking my degree at Istituto Marangoni I completed a six month internship at Julien Macdonald. During this period I was entasked with a variety of roles. This included creating a system to log the company’s archived garments, as well as more technical work such as hand-embroidering garments for a landmark exhibition. I also gained experience in buying and merchandising and was a valued member of the team. In my role as a buying and merchandising intern, I monitored weekly sales, prepared trend reports, assisted with presentations for clients, and created research boards for new collections. I also helped backstage at the A/W16 Fashion Show and assisted with invitations and sponsorship for the show. 
          </p>
          <p>
            Prior to Julien Macdonald, and while still studying at London College of Fashion, I teamed up with two entrepreneurs who wanted to start up a new premium design label in India. I was asked to join the team as their  design intern.  Working out of London we collaborated long distance for over a year. I flew to India to direct the photo shoot for the look book. I stylised each outfit including hair and make-up.on mood boards, colour boards, and inspiration boards for the designers. I styled all the models for their S/S16 lookbook, which was circulated to retailers including Harrods, Selfridges, and Harvey Nichols.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
